import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './Gallery.css';

const Gallery = ({ images }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [filteredImages, setFilteredImages] = useState(images);

  // Create a Set to store unique categories
  const uniqueCategories = new Set();
  images.forEach((image) => uniqueCategories.add(image.category));

  // Convert the Set object to an array
  const uniqueCategoriesArray = Array.from(uniqueCategories);

  const handleFilter = (category) => {
    setActiveFilter(category);
    if (category === null) {
      setFilteredImages(images);
    } else {
      // Filter the images by category
      const filtered = images.filter((image) => image.category === category);
      setFilteredImages(filtered);
    }
  };

  return (
    <React.Fragment>
      <Container id="gallery" className="gallery">
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  Take a look
                </p>
                <h3>Automotive Gallery</h3>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
          </Row>
        <Row className="m-4">
          <Col>
            <Button
              className={`gallery-filter-button ${activeFilter === null ? 'active' : ''}`}
              onClick={() => handleFilter(null)}
            >
              All
            </Button>
            {uniqueCategoriesArray.map((category) => (
              <Button
                key={category}
                className={`gallery-filter-button ${activeFilter === category ? 'active' : ''}`}
                onClick={() => handleFilter(category)}
              >
                {category}
              </Button>
            ))}
          </Col>
        </Row>
        <Row>
          {filteredImages.map((image) => (
            <Col key={image.imgPath} sm={6} md={4} lg={3}>
              <img src={image.imgPath} alt={"Image from " + image.category} className="img-fluid mb-3 rounded sm" />
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Gallery;
