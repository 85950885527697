import React from "react";
import { Col, Container, Row, Form, Label, Input, FormGroup } from "reactstrap";

const Card = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className="contact-info text-center mt-5">
          <div className="icon">
            <i className={props.iconClass}></i>
          </div>
          <div className="mt-4 pt-2">
            <h6 className="fs-17">{props.title}</h6>
            {props.isChildItem.map((item, key) => (
              <p className="text-muted mb-0" key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

// Contact Data

const ContactData = [
  {
    title: "Our Shop",
    iconClass: "mdi mdi-map-marker text-primary h4",
    isChildItem: ["601 Walnut Alley, New Cumberland PA 17070, United States"],
  },
  {
    title: "Phone",
    iconClass: "mdi mdi-phone text-primary h4",
    isChildItem: [<a style={{textDecoration: "none"}} href="tel:+17173173922">+1 717-317-3922</a>],
  },
  {
    title: "Instagram",
    iconClass: "mdi mdi-instagram text-primary h4",
    isChildItem: [<a style={{textDecoration: "none"}} href="https://instagram.com/redefinedautomotive/">@redefinedautomotive</a>],
  },
  {
    title: "Working Hours",
    iconClass: "mdi mdi-calendar-clock text-primary h4",
    isChildItem: ["Monday-Friday: 9:00am-03:30pm", "Saturday-Sunday: Closed"],
  },
];

const Contact = () => {
  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center mb-4">
                <p className="text-uppercase text-muted mb-2">Contact</p>
                <h3 className="text-uppercase">Get In Touch</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                Redefined Automotive enhances vehicles through vinyl wraps, paint protection film, window tint, and ceramic coating for a superior driving experience.
                </p>
              </div>
            </Col>
          </Row>

          {/*  */}

          <Row className="justify-content-center">
            {/* Contact Component Start */}

            {ContactData.map((value, idx) => (
              <Card
                key={idx}
                title={value.title}
                iconClass={value.iconClass}
                isChildItem={value.isChildItem}
              />
            ))}
            {/* Contact Component End */}
          </Row>

          <Row className="mt-5 pt-2 justify-content-center">
            <Col lg={9}>
              <div className="custom-form">
                <form name="contact-form" method="post">
                  <p id="error-msg"></p>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <input type="hidden" name="form-name" value="contactform" />
                          <Label for="name" className="form-label">
                            Name:&nbsp;*
                          </Label>
                          <Input
                            name="name"
                            id="name"
                            required
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Email address:
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Your Email Address"
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="number" className="form-label">
                            Phone number:&nbsp;*
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            name="number"
                            required
                            id="number"
                            placeholder="Your Phone Number"
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={12}>
                      <FormGroup>
                        <div className="mb-3">
                          <Label for="comments" className="form-label">
                          Message:&nbsp;*
                          </Label>
                          <Input
                            type="textarea"
                            required
                            className="form-control"
                            placeholder="Write your message/inquiry"
                            name="message"
                            id="message"
                            style={{ height: "100px" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className="mt-3 text-end">
                        
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary"
                            value="Submit Form"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>

          <Row>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3045.062442729276!2d-76.97142662335831!3d40.252143565639116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8c3261fb75301%3A0x724bc047e1392551!2sRedefined%20Automotive!5e0!3m2!1sen!2s!4v1698233821513!5m2!1sen!2s" width="350" height="350" style={{border: 0, marginTop: 10}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
