import React from "react";

import About from "../components/About";
import BackToTop from "../components/BackToTop";
import Contact from "../components/Contact";
import Footer from "../Layout/Footer";
import FooterAlt from "../Layout/FooterAlt";
import Services from "../components/Services";
import Gallery from "../components/Gallery";

import ppf1 from "../assets/images/gallery/PPF/1.jpg";
import ppf2 from "../assets/images/gallery/PPF/2.jpg";
import ppf3 from "../assets/images/gallery/PPF/3.jpg";
import ppf4 from "../assets/images/gallery/PPF/4.jpg";
import ppf5 from "../assets/images/gallery/PPF/5.jpg";
import ppf6 from "../assets/images/gallery/PPF/6.jpg";
import cc1 from "../assets/images/gallery/CeramicCoating/1.jpeg";
import cc2 from "../assets/images/gallery/CeramicCoating/2.jpeg";
import tint1 from "../assets/images/gallery/Tints/1.jpeg";
import tint2 from "../assets/images/gallery/Tints/2.jpeg";
import vinyl1 from "../assets/images/gallery/VinylWraps/1.jpeg";
import vinyl2 from "../assets/images/gallery/VinylWraps/2.png";
import vinyl3 from "../assets/images/gallery/VinylWraps/3.png";
import vinyl4 from "../assets/images/gallery/VinylWraps/4.jpg";

const GalleryImages = [
  {
    imgPath: ppf1,
    category: "PPF"
  },
  {
    imgPath: ppf2,
    category: "PPF"
  },
  {
    imgPath: ppf3,
    category: "PPF"
  },
  {
    imgPath: ppf4,
    category: "PPF"
  },
  {
    imgPath: ppf5,
    category: "PPF"
  },
  {
    imgPath: ppf6,
    category: "PPF"
  },
  {
    imgPath: cc1,
    category: "Ceramic Coating"
  },
  {
    imgPath: cc2,
    category: "Ceramic Coating"
  },
  {
    imgPath: tint1,
    category: "Tint"
  },
  {
    imgPath: tint2,
    category: "Tint"
  },
  {
    imgPath: vinyl1,
    category: "Vinyl Wraps"
  },
  {
    imgPath: vinyl2,
    category: "Vinyl Wraps"
  },
  {
    imgPath: vinyl3,
    category: "Vinyl Wraps"
  },
  {
    imgPath: vinyl4,
    category: "Vinyl Wraps"
  }
];

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <About />
      <Services />
      <Gallery images={GalleryImages} />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

/** removed sections
 * <Services />
      <About />
      <Process />
      <Counter />
      <Testimonial />
      <Team />
      <Portfolio />
      <Pricing />
      <Blog />
      <CTA />
      <Contact />
      <Footer />
 */

export default Layout;
