import React from "react";

import about from "../assets/images/aboutImage.webp";
import { Container, Row, Col } from "reactstrap";

const About = () => {
  return (
    <React.Fragment>
      <section id="about" className="section bg-light">
        <Container>
          <Row className="align-items-center g-3 g-lg-0">
            <Col lg={6}>
              <div className="skill-box bg-white p-4 rounded box-shadow">
                <p className="text-uppercase mb-1">Who are we?</p>
                <h3 className="mb-2">
                  Welcome To RDF
                </h3>
                <p className="text-muted">
                Redefined Automotive, your trusted partner in the world of automotive customization. We're passionate about enhancing your vehicle's appearance and protection. Our range of services includes vinyl wraps, paint protection film (PPF), window tint, and ceramic coating, all designed to elevate your driving experience.
                </p>
              </div>
            </Col>
            <Col className="about-img" lg={6}>
              <img
                src={about}
                className="img-fluid about-image rounded box-shadow"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default About;
