import React from "react";
import { Col, Container, Row } from "reactstrap";

const ServicesCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          {props.imgsrc ? <img src={props.imgsrc} alt="" className="img-fluid" /> : ""}
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Services data

const ServicesData = [
  {
    title: "Vinyl Wraps",
    caption:
      "Discover the art of vehicle personalization with our vinyl wraps. Our skilled team can turn your car into a head-turning masterpiece. Whether you're looking for a bold design or a sleek color change, our vinyl wrap experts have you covered. Elevate your branding or express your unique style with our custom vehicle wrap solutions. From commercial advertising wraps to individualized designs, we bring your vision to life on the road!",
  },
  {
    title: "PPF (Paint Protection Film)",
    caption: "Preserve your vehicle's pristine finish with our cutting-edge paint protection film. Our PPF services offer a nearly invisible shield that defends your car against road debris, scratches, and the elements, ensuring your paint stays flawless for years to come."
  },
  {
    title: "Tint",
    caption: "Enhance your driving experience with our window tinting services. Not only does tinting provide privacy, but it also blocks harmful UV rays and keeps your car's interior cooler, all while adding a touch of elegance."
  },
  {
    title: "Ceramic Coating",
    caption: "Experience the ease of maintenance and shine with our ceramic coating service. Our advanced ceramic coatings create a hydrophobic barrier, repelling dirt and water while delivering a lasting glossy finish that's second to none."
  }
];

const Services = () => {
  return (
    <>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  Services We Provide
                </p>
                <h3>We do all sorts of services that fall in vehicle customization & protection</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                  We can help protect your vehicle from heavy terrain, regular wear-and-tear, and more.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Services Component Start */}

            {ServicesData.map((value, idx) => (
              <ServicesCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Services Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Services;
